import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import { Contact } from "./Contact";
import { InDevelopment } from "./InDevelopment";
import { LandingPage } from "./LandingPage";
// Projects // Contact // About
// Box for each one

// Madre films is a UK based production company focussod on developing new scripts and talent,
// especially from traditionally underrepresented communities.

//

const Footer = () => (
  <footer>
    <p className="p-4 w-full text-center text-gray-400">
      &copy; 2021 Madre Films Ltd. All rights reserved.
    </p>
  </footer>
);

const App = () => {
  return (
    <div className="bg-gray-700 min-h-screen min-w-screen text-white flex flex-col justify-between">
      <Router>
        <Switch>
          <Route exact path="/" component={LandingPage} />
          <Route exact path="/projects" component={InDevelopment} />
          <Route exact path="/contact" component={Contact} />
        </Switch>
      </Router>
      <Footer />
    </div>
  );
};

export default App;
