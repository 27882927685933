import logo from "./svg/logo_white.svg";
import { ChevronRightIcon } from "@heroicons/react/solid";
import { Link } from "react-router-dom";

export function LandingPage() {
  return (
    <div className="flex-grow p-2 flex flex-col items-center justify-center">
      <Link
        className="flex flex-col items-center uppercase hover:underline"
        to="/contact"
      >
        <img src={logo} className="w-full max-w-xl p-8" alt="logo" />
        <div className="flex items-center">
          Enter <ChevronRightIcon className="h-6 w-6 " />
        </div>
      </Link>
    </div>
  );
}
