export function InDevelopment() {
  return (
    <div className="text-white">
      <p>Shorts in development:</p>
      <ul className="pl-4">
        <li>White Girl</li>
        <li>Home Safe</li>
        <li>The Drive Home</li>
        <li>Nuclear</li>
      </ul>
      <p>Features in development:</p>
      <ul className="pl-4">
        <li>She can be Heroes</li>
      </ul>
    </div>
  );
}
